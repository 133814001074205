//获取url参数
export function getUrlParams() {
			const url =window.location.href
		    let pattern = /(\w+)=(\w+)/ig; //定义正则表达式
		    let parames = {}; // 定义参数对象
		    url.replace(pattern, ($, $1, $2) => {
		 parames[$1] = $2;
		    });
		  return parames;
		}
// 修改语言然后刷新页面
export function changeLang(name,_this) {
	localStorage.setItem('lang', name);
	_this.$i18n.locale = name//切换语言包
	_this.lang=name//下拉列表title更换名称
	location.reload()
}

// 删除url中的某个字段 并 重新刷新url
export function delUrlQuery(param) {
	if(location.href.indexOf(param+'=')>0){
		let url=ridUrlParam(location.href,[param]);
		setTimeout(()=>{
			window.location.href=url
		},100)
	}
	console.log("刷新---------",window.location.href)
}

/**
 * 删除URL中指定search参数,会将参数值一起删除
 * @param {string} url 地址字符串
 * @param {array} aParam 要删除的参数key数组，如['name','age']
 * @return {string} 返回新URL字符串
 */
export function ridUrlParam(url,aParam){
    aParam.forEach(item => {
      const fromindex = url.indexOf(`${item}=`) //必须加=号，避免参数值中包含item字符串
      if (fromindex !== -1) {
        // 通过url特殊符号，计算出=号后面的的字符数，用于生成replace正则
        const startIndex = url.indexOf('=', fromindex)
        const endIndex = url.indexOf('&', fromindex)
        const hashIndex = url.indexOf('#', fromindex)
        
        let reg;
        if (endIndex !== -1) { // 后面还有search参数的情况
          const num = endIndex - startIndex
          reg = new RegExp(`${item}=.{${num}}`)
          url = url.replace(reg, '')
        } else if (hashIndex !== -1) { // 有hash参数的情况
          const num = hashIndex - startIndex - 1
          reg = new RegExp(`&?${item}=.{${num}}`)
          url = url.replace(reg, '')
        } else { // search参数在最后或只有一个参数的情况
          reg = new RegExp(`&?${item}=.+`)
          url = url.replace(reg, '')
        }
      }
    });
    const noSearchParam = url.indexOf('=') 
    if( noSearchParam === -1 ){
      url = url.replace(/\?/, '') // 如果已经没有参数，删除？号
    }
    return url    
}
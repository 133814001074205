<template>
	<div class="head">
		<div class="status">
			<div class="container flex a-center jlr">
				<a href="">{{ $t('myhead.loginName') }}</a>
				<div class="lang">
					<el-dropdown>
						<span class="el-dropdown-link">
							{{lang | langFilter}}<i class="el-icon-arrow-down el-icon--right"></i>
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item @click.native="langFn('en')">English</el-dropdown-item>
							<el-dropdown-item @click.native="langFn('zh')">简体中文</el-dropdown-item>
							<el-dropdown-item @click.native="langFn('vi')">Tiếng Việt</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
			</div>
		</div>
		<div class="container headBody flex a-center jlr">
			<div class="logo flex a-center j-center">LOGO</div>
			<div class="nav">
				<ul class="flex navUl">
					<li>手机</li>
					<li>手机</li>
					<li>手机</li>
					<li>手机</li>
				</ul>

			</div>

		</div>
	</div>
</template>

<script>
	export default {
		data(){
			return {
				lang:localStorage.getItem('lang')
			}
		},
		filters:{
			langFilter(name){
				switch (name){
					case 'en':
						return 'English'
						break;
					case 'zh':
						return '简体中文'
						break;
					case 'vi':
						return 'Tiếng Việt'
						break;
				}
			}
		},
		methods:{
			langFn(name){
				localStorage.setItem('lang',name);
				location.reload();
			}
		}
	}
</script>

<style scoped="scoped">
	.status {
		width: 100%;
		background-color: #e3e4e5;
		height: 30px;
		line-height: 30px;
	}

	.headBody {
		padding: 20px 0px;
	}

	.logo {
		width: 134px;
		height: 120px;
		background-color: #e2231a;
	}

	.search {
		width: 550px;
		height: 46px;
		border: 2px solid #e2231a;
		border-right: 0;
	}

	.search input {
		flex: 1;
		outline: none;
		border: 0;
		height: 100%;
		padding: 0px 20px;
		font-size: 14px;
	}

	.search_button {
		background-color: #e2231a;
		padding: 0px 25px;
		color: #fff;
		cursor: pointer;
	}

	.headImage {
		width: 150px;
		height: 120px;
		background-color: #eee;
	}

	.navUl {
		padding-top: 30px;
	}

	.navUl li {
		padding: 0px 12px;
		margin-right: 10px;
		cursor: pointer;
	}

	.navUl li:hover {
		color: #e2231a;
	}
</style>

import Vue from 'vue'
/*头部组件*/
import myhead from './myhead.vue'
import top from './top.vue'
import productLi from './productLi.vue'
import footerbox from './footerbox.vue'
import step from './step.vue'
import mybanner from './swiperBanner.vue'
import newbanner from './newbanner.vue'
Vue.component('top',top)
Vue.component('myhead',myhead)
Vue.component('productLi',productLi)
Vue.component('footerbox',footerbox)
Vue.component('step',step)
Vue.component('mybanner',mybanner)
Vue.component('newbanner',newbanner)
/*头部组件End*/


import Vue from 'vue'
import App from './App.vue'
import router from './router'//引用路由文件
import store from './store'//引用Vuex
import ElementUI from 'element-ui';//引用Element-ui
import cookie from 'js-cookie'//引用cookieJs
import 'element-ui/lib/theme-chalk/index.css';//引入全局Element-ui文件
import './assets/css/main.css'//引入全局CSS文件
import './components/index.js'//引入全局组件文件
import { i18n } from './lang'//引入多语言
import './assets/css/animate.css';//引入动画css
Vue.use(ElementUI,{
	i18n: (key, value) => i18n.t(key, value), // 这里会根据选的语言切换Element-ui的语言
});
Vue.prototype.$cookie = cookie//暴露cookie
Vue.config.productionTip = false
var lang = localStorage.getItem('lang');
if(lang=='ru'){
	document.querySelector('body').className="ru"
}


window.vm = new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  beforeCreate() {
  	Vue.prototype.$bus = this
  }
}).$mount('#app')

import Vue from 'vue'
import Element from 'element-ui'
import VueI18n from 'vue-i18n'
import locale from 'element-ui/lib/locale'
import enLocale from 'element-ui/lib/locale/lang/en'//英语
import ruLocale from 'element-ui/lib/locale/lang/ru-RU'//俄语
import viLocale from 'element-ui/lib/locale/lang/vi'//越南语
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'//中文
// 
import idLocale from 'element-ui/lib/locale/lang/id'//印尼语
import jaLocale from 'element-ui/lib/locale/lang/ja'//日语
import koLocale from 'element-ui/lib/locale/lang/ko'//韩语
import thLocale from 'element-ui/lib/locale/lang/th'//泰语
import zhTWLocale from 'element-ui/lib/locale/lang/zh-TW'//繁体
import eoLocale from 'element-ui/lib/locale/lang/eo'//马来西亚语（暂无 用世界语代替）

import en from './en'
import ru from './ru'
import vi from './vi'
import zh from './zh'
import id from './id'
import ja from './ja'
import ko from './ko'
import th from './th'
import zhTW from './zhTW'
import eo from './eo'
Vue.use(VueI18n)

const messages = {
  zh: {
     language: 'zh',
     ...zh,
    ...zhLocale
  },
  en: {
    language: 'en',
    ...en,
    ...enLocale
  },
  ru: {
    language: 'ru',
    ...ru,
    ...ruLocale
  },
  vi: {
    language: 'vi',
    ...vi,
    ...viLocale
  },  
  id:{
	 language: 'id',
	 ...id,
	 ...idLocale 
  },
  ja:{
  	 language: 'ja',
  	 ...ja,
  	 ...jaLocale 
  },
  ko:{
  	 language: 'ko',
  	 ...ko,
  	 ...koLocale 
  },
  th:{
  	 language: 'th',
  	 ...th,
  	 ...thLocale 
  },
  "zh-tw":{
  	 language: 'zh-tw',
  	 ...zhTW,
  	 ...zhTWLocale 
  },
  ms:{
  	 language: 'ms',
  	 ...eo,
  	 ...eoLocale 
  },
  
}
const i18n = new VueI18n({
  locale: localStorage.getItem('lang') || 'en',
  messages
})

locale.use(Element, {
  i18n: (key, value) => i18n.t(key, value)
})
export { i18n, messages }
<template>
  <div id="app">
    <!-- 		<transition enter-active-class="animate__animated animate__zoomIn">
			<router-view />
		</transition> -->
    <!-- enter-active-class="animate__animated animate__zoomIn" -->
    <!-- <transition >
			<router-view />
		</transition> -->

    <router-view />
  </div>
</template>
<script>
import { getUrlParams, delUrlQuery } from "@/utils/util.js";
export default {
  // created() {
  // 	function getUrlParams(name) {
  // 		try{
  // 			var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i"); //定义正则表达式
  // 			var str = window.location.search.split('?')[1]
  // 			var r = str.match(reg);
  // 			if (r != null) {
  // 				return unescape(r[2]);
  // 			}
  // 		}catch(e){

  // 		}
  // 		return null;
  // 	}
  // 	var lang = getUrlParams('lang');
  // 	if(lang){
  // 		if(lang!==localStorage.getItem('lang')){
  // 			localStorage.setItem('lang',lang);
  // 			location.reload()
  // 		}
  // 	}else{
  // 		localStorage.setItem('lang','en');
  // 	}

  // },
  mounted() {
	  try{
	  	const lang = getUrlParams().lang;
	  	if (lang.length>0) {
	  	  localStorage.setItem("lang", lang); //当前语言环境存入缓存
	  	  this.$i18n.locale = lang; //切换语言
	  	  delUrlQuery("lang"); //删除url中的字段
	  	} else {
	  	  if (!localStorage.getItem("lang")) {
	  	    let a = navigator.language;
	  	    a = a == "zh-CN" ? "zh" : a;
	  	    a = a == "zh-TW" ? "zh-tw" : a;
	  	    localStorage.setItem("lang", a); //当前语言环境存入缓存
	  	    this.$i18n.locale = a; //切换语言
	  		delUrlQuery("lang"); //删除url中的字段
	  	  }
	  	}
	  }catch(e){
	  	//TODO handle the exception
	  }
   
  },
};
</script>
<template>
	<div class="step flex a-center">
		<div class="step-icon">1</div>
		<div class="step-icon">2</div>
		<div class="step-icon">3</div>
		<div class="step-icon">4</div>
	</div>
</template>

<script>
export default {
	props:{
		active:{
			require:true,
			type:Number
		}
	},
	data(){
		return {
			
		}
	}
}
</script>

<style scoped>

</style>
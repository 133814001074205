import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
	   list:[
		   {url:'https://element.eleme.cn/static/theme-index-blue.c38b733.png',title:'产品说明产品说明产品说明产品说明产品说明',price:100,count:1},
		   {url:'https://element.eleme.cn/static/theme-index-blue.c38b733.png',title:'产品说明产品说明产品说明产品说明产品说明',price:100,count:1},
		   {url:'https://element.eleme.cn/static/theme-index-blue.c38b733.png',title:'产品说明产品说明产品说明产品说明产品说明',price:100,count:1},

		   ]
  },
  getters: {
  },
  mutations: {
	  removecar(state,params){
	     state.list.splice(params,1)
	    },
	  addCar(state,params){
		  state.list.push(params)
	  }
  },
  actions: {
  },
  modules: {
  }
})

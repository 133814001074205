<template>
	<div class="swiper-banner">
		<div class="swiper" :ref="refTime">
			<div class="swiper-wrapper">
				<!-- <slot>
					<div class="swiper-slide" v-for="item in data"><img :src="item.img"></div>
				</slot> -->
				<div class="swiper-slide">
					<img src="../assets/img/banner_bg1.png">
					<div class="banner-container flex a-center ">
						<div class="b1Img"><img src="../assets/img/banner_left01.png"></div>
						<div class="banner_center">
							<h2>{{$t('Home.Home_a')}}</h2>
							<h2>{{$t('Home.Home_b')}}</h2>
							<p>{{$t('Home.Home_c')}}</p>
						</div>
						<div class="banner_right_top">
							<h4>Global access </h4>
							<h4>Local expertise</h4>
						</div>
					</div>
				</div>
				
				<div class="swiper-slide">
					<img src="../assets/img/banner_bg1.png">
					<div class="banner-container flex a-center ">
						<div class="b1Img"><img src="../assets/img/banner_left01.png"></div>
						<div class="banner_center">
							<h2>{{$t('Home.Home_a')}}</h2>
							<h2>{{$t('Home.Home_b')}}</h2>
							<p>{{$t('Home.Home_c')}}</p>
						</div>
						<div class="banner_right_top">
							<h4>Global access </h4>
							<h4>Local expertise</h4>
						</div>
					</div>
				</div>
			</div>
			
			<div class="swiper-button next"></div>
			<div class="swiper-button prev"></div>
			<div class="swiper-pagination"></div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			data: {
				type: Array,
				default: () => {
					return []
				}
			}
		},
		data() {
			let time = new Date().getTime() + '' + Math.ceil(Math.random() * 10000)
			return {
				refTime: time
			}
		},
		mounted() {
			let dom = this.$refs[this.refTime];
			var swiper = new Swiper(dom, {
				// autoplay: {
				// 	delay: 5000, //5秒切换一次
				// },
				loop: true,
				pagination: {
					el: ".swiper-pagination",
					clickable: true,
				},
				navigation: {
					nextEl: ".next",
					prevEl: ".prev",
				},
			});
		}
	}
</script>

<style scoped>
	.swiper-banner {
		width: 100%;
		height: 100%;
		position: relative;
	}

	.swiper {
		width: 100%;
		height: 100%;
	}

	.swiper-slide {
		display: -webkit-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		-webkit-align-items: center;
		align-items: center;
	}

	.swiper-slide img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.swiper-button {
		/* background-color: #f60; */
		position: absolute;
		right: 2%;
		bottom: 8%;
		width: 11px;
		height: 16px;
		cursor: pointer;
		z-index: 99;
		
	}

	.swiper-button.prev {
		right: calc(0% + 40px);
		background: url('@/assets/img/navigate_next.png');
	}
	
	
	.swiper-button.next {
		right: calc(5% + 40px);
		background: url('@/assets/img/chevron_left.png');
	}

	/deep/ .swiper-pagination-bullet {
		display: block !important;
		width: 6px !important;
		height: 6px !important;
		position: relative;
		margin: 12px 0 !important;
		/* opacity: 1 !important; */
		background: #fff !important;
	}

	/deep/ .swiper-pagination-bullet-active::before {
		content: '';
		display: block;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 12px;
		height: 12px;
		border-radius: 50%;
		border: 1px solid #fff;
	}

	/deep/ .swiper-container-horizontal>.swiper-pagination-bullets,
	/deep/ .swiper-pagination-custom,
	.swiper-pagination-fraction {
		bottom: auto !important;
		left: 15px !important;
		top: 50% !important;
		transform: translateY(-50%) !important;
		width: auto !important;
	}
	
	
	.banner_left {
		top: 50% !important;
		transform: translateY(-50%);
	}
	
	.banner_left h2 {
		font-size: 38px !important;
	}
	
	.banner_left p {
		color: #d7dbde;
		font-size: 16px;
		width: 600px;
		line-height: 30px;
	}
	.banner-container{
		    width: 1140px;
		    position: absolute;
		    left: 50%;
		    top: 0%;
		    transform: translateX(-50%);
		    height: auto;
	}
	
	.b1Img{width: 50%;}
	
	.banner_center{}
		
	.banner_center h2{ 
		    color: #fff;
		    font-size: 32px;
		    line-height: 38px;
	}
		
	.banner_center p{
		    color: #fff;
		    font-size: 16px;
		    width: 390px;
		    margin-top: 30px;
	}
	
	.banner_right_top{
		    position: absolute;
		    text-align: right;
		    color: rgb(255 255 255 / 75%);
		    top: 12px;
		    right: 0;

	}
	
		.banner_right_top h4{
			font-size: 16px;
			font-weight: normal;
		}
</style>

<template>
  <li @click="enter(item.no)">
    <!-- <div class="image"> -->
    <div
      class="image"
      v-for="(v, i) in item.thumbnail"
      :src="v"
      :key="i"
      v-if="!i"
      :style="{ backgroundImage: 'url(' + v + ')' }"
    >
      <!-- <img  v-for="(v,i) in item.thumbnail" :src="v" :key="i" v-if="!i"> -->
    </div>
    <div class="proInfo">
      <h2>{{ item.title }}</h2>
      <div class="proInfo_item flex a-center jlr">
        <!-- <div class="pirce">3500积分</div> -->
        <div class="pirce flex a-center jlr">
          <div class="pirce_icon"></div>
          <div class="pirce_wenan">
            {{ item.points }}{{ $t("Home.Home_y") }}
          </div>
        </div>
        <div class="button">{{ $t("Home.Home_x") }}</div>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  props: ["item"],
  data() {
    return {};
  },
  methods: {
    enter(id) {
      this.$router.push("/detail/" + id);
    },
  },
};
</script>

<style scoped>
li {
  width: 25%;
  height: 50%;
  margin: 0;
  cursor: pointer;
  /* border: 1px solid #DEE2E6; */
  border-radius: 0;
  padding: 6px;
}

.proInfo {
  padding: 16px;
  margin-top: -1px;
  border: 1px solid #dee2e6;
  border-radius: 0 0 12px 12px;
}

h2 {
  font-size: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #212529;
  font-weight: bold;
  margin-bottom: 24px;
  line-height: 27px;
  height: 50px;
}

.image {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-bottom: calc(100% - 2px);
  border: #dee2e6 solid 1px;
  border-radius: 12px 12px 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* object-fit: scale-down; */
}

.image img {
  width: 100%;
  height: auto;
  /* object-fit: inherit; */
  /* background-size: contain; */
  border-radius: 12px 12px 0px 0px;
  object-position: center center;
  display: block;
}

.pirce {
  font-size: 14px;
  color: rgba(33, 37, 41, 0.75);
  line-height: 22px;
}

.pirce_icon {
  background: url("@/assets/img/price_icon.png");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  width: 21px;
  height: 22px;
  margin-right: 5px;
}

.button {
  border: 1px solid #212529;
  color: #212529;
  padding: 4px;
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  border-radius: 6px;
  line-height: 24px;
  max-width: 135px;
  word-wrap: break-word;
  word-break: normal;
}

.button:hover {
  background: #212529;
  color: #fff;
}

/* 当页面宽度大于750px小于1200px */
@media screen and (min-width: 750px) and (max-width: 1160px) {
  li {
    width: 44%;
    margin: 3%;
  }

  .image {
    width: 100% !important;
    overflow: hidden;
  }

  .proInfo_item {
    display: block;
  }

  .button {
    margin-top: 20px;
  }
}

/* 兼容手机 */
@media screen and (max-width: 750px) {
  li {
    width: 50%;
    margin: 0;
  }

  .image {
    width: 100% !important;
    overflow: hidden;
    height: 0;
  }

  .proInfo_item {
    display: block;
  }

  .button {
    margin-top: 20px;
  }
}
</style>
